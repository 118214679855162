<template>
	<form class="detail notification-detail" @submit.prevent="saveClick">
		<h4>{{ notificationFormTitle }}</h4>
		<i class="detail-header-icon hide detail-hide" @click="closeView" title="Close">✕</i>

		<slot></slot>

		<h5 class="select-browsers-title">Notify Browsers</h5>
		<browser-select v-model="notification.allowed_browsers" />

		<h5>Content (Required)</h5>
		<section class="block">
			<div class="row">
				<div class="col">
					<span class="input-label">Title</span>
					<input v-focus type="text" v-model.trim="notification.title">
				</div>
			</div>
			<div class="row">
				<div class="col">
					<span class="input-label">Message*</span>
					<textarea v-model.trim="notification.message_text" required></textarea>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<span class="input-label">Labels</span>
					<tags :tags="notification.labels"></tags>
				</div>
			</div>
		</section>

		<h5>Header Image (Optional)</h5>
		<section class="block upload-image">
			<div class="row">
				<div class="col">
					<span class="input-label">Image URL</span>
					<input type="url" :disabled="uploadingImage" v-model.trim="notification.image_url">
				</div>
			</div>
			<div class="row">
				<div class="col">
					<span class="input-label">Upload Image</span>
					<input type="file" :disabled="uploadingImage" @change="uploadImage">
				</div>
			</div>
			<div class="row">
				<div class="col">
					<img class="full-width" v-if="notification.image_url" :src="notification.image_url" alt="Preview image" />
				</div>
			</div>
			<div v-if="uploadingImage" class="upload-image-message">
				<span>Uploading Image...</span>
			</div>
			<div class="row">
				<div class="col">
					<span class="input-label">Image has white background</span>
					<input type="checkbox" v-model="notification.image_has_white_background" :disabled="uploadingImage || !notification.image_url">
				</div>
			</div>
		</section>

		<h5>First Button (Optional)</h5>
		<section class="block">
			<div class="button" @click="() => {notification.f_cta_text = 'Check it out'; notification.f_cta_options = '{ \'url\': \'https://momentumdash.com/plus/\' }'; notification.f_cta_cmd = 'window.open';}" >External link template</div>
			<div class="row">
				<div class="col-6">
					<span class="input-label">Button text</span>
					<input type="text" v-model.trim="notification.f_cta_text">
				</div>
				<div class="col-6">
					<span class="input-label">Action</span>
					<input type="text" v-model.trim="notification.f_cta_cmd" placeholder="E.g. settings.display or window.open">
				</div>
			</div>
			<div class="row">
				<div class="col">
					<span class="input-label">Options JSON</span>
					<textarea v-model.trim="notification.f_cta_options" placeholder="E.g. { 'url': 'https://momentumdash.com/blog/' }"/>
				</div>
			</div>
		</section>

		<h5>Second Button (Optional)</h5>
		<section class="block">
			<div class="row">
				<div class="col-6">
					<span class="input-label">Button text</span>
					<input type="text" v-model.trim="notification.s_cta_text">
				</div>
				<div class="col-6">
					<span class="input-label">Action</span>
					<input type="text" v-model.trim="notification.s_cta_cmd" placeholder="E.g. settings.display or window.open">
				</div>
			</div>
			<div class="row">
				<div class="col">
					<span class="input-label">Options JSON</span>
					<textarea v-model.trim="notification.s_cta_options" placeholder="E.g. { 'url': 'https://momentumdash.com/blog/' }"/>
				</div>
			</div>
		</section>

		<h5>Schedule & Audience (Required For Send)</h5>
		<section class="block">
			<div class="row">
				<div class="col-2">
					<span class="input-label">Audience*</span>
				</div>
				<div class="col-2">
					<span class="input-label">Plus</span>
					<input type="checkbox" v-model="notification.plus">
				</div>
				<div class="col-2">
					<span class="input-label">Free</span>
					<input type="checkbox" v-model="notification.free">
				</div>
			</div>
			<div class="row">
				<div class="col">
					<span class="input-label">Select Notification Group*</span>
					<select v-model="notification.campaign_group">
						<option selected disabled>----</option>
						<option value="">*** New Group ***</option>
						<option v-for="group in campaignGroups" :key="group.id" :value="group.id">{{ group.name }}</option>
					</select>
					<div v-if="showNewGroupInput">
						<span class="input-label">New Notification Group</span>
						<input type="text" v-model.trim="notification.new_campaign_group_name" placeholder="New Group Name">
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-6">
					<span class="input-label">Start Date* (local)</span>
					<input class="col" type="datetime-local" :value="localStartDate" @change="setStartDate($event.target.value)">
					<div class="helper">utc: {{ notification.start_date }}</div>
				</div>
				<div class="col-6">
					<span class="input-label">End Date (local) (optional)</span>
					<input class="col" type="datetime-local" :value="localEndDate" @change="setEndDate($event.target.value)">
					<div class="helper">utc: {{ notification.end_date }}</div>
				</div>
			</div>
			<div class="row">
				<div class="col-6">
					<span class="input-label">Target Area (optional)</span>
					<select v-model="notification.target_area">
						<option selected value="">----</option>
						<option v-for="targetArea in notificationTargetAreas" :key="targetArea.id" :value="targetArea.id">{{ targetArea.name }}</option>
					</select>
				</div>
				<div class="col-6">
					<span class="input-label">Extension Minimum Version (optional)</span>
					<select v-model="notification.extension_min_version">
						<option selected value="">----</option>
						<option v-for="version in versions" :key="version.id" :value="version.id">{{ version.version }}</option>
					</select>
				</div>
				<div class="col-12">
					<span class="input-label">Quantity (optional)</span>
					<input type="number" v-model.trim="notification.quantity" placeholder="Default: 2000000">
					<div class="helper">
						For notifications to Free users, include only half of the intended audience size. Unregistered users are not counted in the quantity but still receive the notifications. For example, if the intended number of notifications is 10000 (and it's to Free users), put 5000 as the quantity.
					</div>
				</div>
			</div>
		</section>

		<input class="submit-button" type="submit" :value="inProgress ? 'Saving...' : 'Save'" @click.prevent="saveClick" :disabled="inProgress">

		<footer>
			<span>{{ errorMessage }}</span>
		</footer>
	</form>
</template>

<script>
import Vue from 'vue'
import Tags from '../shared/Tags'
import BrowserSelect from "@/components/global/BrowserSelect.vue";
import util from "@/util";
import JSON5 from 'json5'

const isJson = (str) => {
	try {
		JSON5.parse(str);
		return true;
	} catch (e) {
		return false;
	}
}
const getDateTimeForInputFromUTC = (date) => {
	// need to remove offset twice. first time to have the date in local time, then to have the UTC conversion return the local time
	return new Date(date.getTime() - (new Date().getTimezoneOffset() * 2 * 60 * 1000)).toISOString().substring(0, 16)
}


export default {
	name: 'notificationForm',
	components: {BrowserSelect, Tags },
	store: ['versions', 'notificationTargetAreas', 'campaignGroups'],
	props: {
		notification: Object,
		notificationFormTitle: String,
		inProgress: Boolean,
		errorMessage: String,
	},
	data() {
		return {
			showNewGroupInput: !this.notification.campaign_group,
			uploadingImage: false,
		}
	},
	watch: {
		'notification.campaign_group'() {
			this.showNewGroupInput = this.notification.campaign_group === ""
			const campaign = this.campaignGroups.find(x => x.id === this.notification.campaign_group)
			this.notification.campaign_group_name = campaign && campaign.name
			if (!this.showNewGroupInput) {
				this.notification.new_campaign_group = ''
			}
		}
	},
	computed: {
		localStartDate() {
			if(!this.notification.start_date) return ''
			return getDateTimeForInputFromUTC(new Date(Date.parse(this.notification.start_date)))
		},
		localEndDate() {
			if(!this.notification.end_date) return ''
			return getDateTimeForInputFromUTC(new Date(Date.parse(this.notification.end_date)))
		},
	},
	methods: {
		closeView() {
			this.$emit('viewClosed')
		},
		saveClick() {
			if(!isJson(this.notification.f_cta_options || '{}')) {
				this.errorMessage = 'The primary cta options is not valid json'
				return
			}
			if(!isJson(this.notification.s_cta_options || '{}')) {
				this.errorMessage = 'The secondary cta options is not valid json'
				return
			}

			this.$emit('saveClick')
		},
		async uploadImage(event) {
			this.uploadingImage = true
			const [, imageUri] = await util.uploadImage(event)
			this.notification.image_url = imageUri
			this.uploadingImage = false
		},
		setStartDate(localDate) {
			if(!localDate) {
				this.notification.start_date = null
			}
			let date = new Date(Date.parse(localDate))
			Vue.set(this.notification, 'start_date', date.toISOString().substring(0, 16))
		},
		setEndDate(localDate) {
			if(!localDate) {
				this.notification.end_date = null
			}
			let date = new Date(Date.parse(localDate))
			Vue.set(this.notification, 'end_date', date.toISOString().substring(0, 16))

		},
	}
}
</script>

<style scoped>
	.u--mr-25 { margin-right: 25px; }

	.toggle { margin: 0; font-size: 1.3em; }
		.detail-row { padding-left: 15px; border: none; }
			.detail-row .switch { right: -20px; margin-left: 5px; }

	.select-browsers-title { margin: 2rem 0 0.75rem; }

	input[type=checkbox] {
		width: auto;
	}

	.helper {
		opacity: 70%;
		font-size: 0.7rem;
		margin-bottom: 15px;
		margin-top: -10px;
	}

	.upload-image { position: relative }
		.upload-image-message { position: absolute; inset: 0; display: flex; align-items: center; justify-content: center; background-color: rgba(0, 0, 0, 0.2); border-radius: 3px; backdrop-filter: blur(0.1rem); }
			.upload-image-message span { font-weight: 700; font-size: 1.5rem; color: white; }

	footer { margin-bottom: 50px; }
		.detail input[type="submit"].submit-button { width: 170px; }
</style>
