import QuoteDetail from './components/quotes/QuoteDetail'
import SubmissionDetail from './components/backgrounds/SubmissionDetail'
import UserDetail from './components/users/UserDetail'
import UserDetailTransfer from './components/users/UserDetailTransfer'
import UserDetailUndelete from './components/users/UserDetailUndelete'
import UserDetailExpireToken from './components/users/UserDetailExpireToken'
import UserDetailCachedData from './components/users/UserDetailCachedData'
import UserDetailHardDeleteUser from './components/users/UserDetailHardDeleteUser'
import UserInstrumentationInfo from './components/users/UserInstrumentationInfo'
import IconDetail from './components/icons/IconDetail'
import QueryDetail from './components/queries/QueryDetail'
import IconDetailEdit from './components/icons/IconDetailEdit'
import IconDetailDelete from './components/icons/IconDetailDelete'
import NotificationDetail from './components/notifications/NotificationDetail'
import Preview from './components/preview/Preview'
import TeamDetail from './components/team/TeamDetail'
import FeatureDetail from '@/components/features/FeatureDetail'
import ExperimentDetail from './components/experiment/ExperimentDetail'
import UserActivityLog from '@/components/users/UserActivityLog'
import Conversion from "./components/experiment/Conversion";
import Churn from "./components/experiment/Churn";
import Growth from "./components/experiment/Growth";
import NotificationStats from "@/components/notifications/NotificationStats.vue";

export default {

	definitions() {
		let routes = [
			{
				path: '/',
				component: () => import (/* webpackChunkName: "dash" */ './components/dash/Dash'),
				meta: { bodyClass: 'dash' }
			},
			{
				path: '/dash',
				component: () => import (/* webpackChunkName: "dash" */ './components/dash/Dash'),
				meta: { bodyClass: 'dash' }
			},
			{
				path: '/news',
				component: () => import (/* webpackChunkName: "news" */ './components/news/News'),
				meta: { bodyClass: 'plus', title: "What's new · Momentum Admin"}
			},
			{
				path: '/quotes',
				component: () => import (/* webpackChunkName: "quotes" */ './components/quotes/Quotes'),
				meta: { bodyClass: 'quotes',
						title: 'Quotes · Momentum Admin' },
				children: [{
					path: ':id',
					component: QuoteDetail,
				}]
			},
			{
				path: '/users',
				component: () => import (/* webpackChunkName: "users" */ './components/users/Users'),
				meta: { bodyClass: 'users', title: 'Users · Momentum Admin' },
				children: [{
					path: ':id',
					component: UserDetail,
					children: [{
						path: 'transfer',
						meta: { viewType: 'nestedDetail' },
						component: UserDetailTransfer
					},
						{
							path: 'user-activity',
							meta: { viewType: 'nestedDetail' },
							component: UserActivityLog
						},
						{
							path: 'undelete',
							meta: { viewType: 'nestedDetail' },
							component: UserDetailUndelete
						},
						{
							path: 'cached-data',
							meta: { viewType: 'nestedDetail' },
							component: UserDetailCachedData
						},
						{
							path: 'expire-token',
							meta: { viewType: 'nestedDetail' },
							component: UserDetailExpireToken
						},
						{
							path: 'delete-user-request',
							meta: { viewType: 'nestedDetail' },
							component: UserDetailHardDeleteUser
						},
						{
							path: 'instrumentation-info',
							meta: { viewType: 'nestedDetail' },
							component: UserInstrumentationInfo
						}
					]
				}]
			},
			{
				path: '/backgrounds',
				component: () => import (/* webpackChunkName: "backgrounds" */ './components/backgrounds/Backgrounds'),
				meta: { bodyClass: 'backgrounds', title: 'Backgrounds · Momentum Admin' },
				children: [
					{
						path:'/backgrounds/:id/preview',
						component: Preview
					}
				]
			},
			{
				path: '/submissions',
				component: () => import (/* webpackChunkName: "submissions" */ './components/backgrounds/Submissions'),
				meta: { bodyClass: 'backgrounds', title: 'Submissions · Momentum Admin' },
				children: [
					{
						path: ':id',
						component: SubmissionDetail,
						meta: { bodyClass: 'detail-view' }
					},
				]
			},
			{
				path: '/mantras',
				component: () => import (/* webpackChunkName: "mantras" */ './components/mantras/Mantras'),
				meta: { bodyClass: 'mantras', title: 'Mantras · Momentum Admin'},
			},{
				path: '/icons',
				component: () => import (/* webpackChunkName: "icons" */ './components/icons/Icons'),
				meta: { bodyClass: 'icons', title: 'Icons · Momentum Admin'},
				children: [{
					path: ':id',
					component: IconDetail,
					children: [{
						path: 'edit',
						meta: { viewType: 'nestedDetail' },
						component: IconDetailEdit
					},
						{
							path: 'delete',
							meta: { viewType: 'nestedDetail' },
							component: IconDetailDelete
						}
						]
				}]
			},{
				path: '/queries',
				component: () => import (/* webpackChunkName: "queries" */ './components/queries/Queries'),
				meta: { bodyClass: 'queries', title: 'Queries · Momentum Admin'},
				children: [{
					path: ':id',
					component: QueryDetail
				}],
				props: true
			},
			{
				path: '/blogs',
				component: () => import (/* webpackChunkName: "blog" */ './components/blog/Blogs'),
				meta: { bodyClass: 'blog', title: 'Blogs · Momentum Admin' }
			},
			{
				path: '/blogs/:id',
				component: () => import (/* webpackChunkName: "blog" */ './components/blog/Blog'),
				meta: { bodyClass: 'blog', title: 'Blog · Momentum Admin' }
			},
			{
				path: '/team',
				name: 'teams',
				component: () => import (/* webpackChunkName: "team" */ './components/team/Team'),
				meta: { bodyClass: 'team', title: 'Team · Momentum Admin' },
				children: [
					{
						name: 'team-detail',
						path: ':id',
						component: TeamDetail
					}
				]
			},
			{
				path: '/experiments-old',
				name: 'experiments-old',
				component: () => import (/* webpackChunkName: "experiments-old" */ './components/experiment/Experiment'),
				meta: { bodyClass: 'experiment', title: 'Experiments · Momentum Admin' },
				children: [
					{
						name: 'experiment-detail',
						path: ':id',
						component: ExperimentDetail
					}
				]
			},
			{
				path: '/conversion',
				name: 'conversion',
				component: Conversion,
				meta: { bodyClass: 'experiment', title: 'Conversion · Momentum Admin' },
			},
			{
				path: '/churn',
				name: 'churn',
				component: Churn,
				meta: { bodyClass: 'experiment', title: 'Churn · Momentum Admin' },
			},
			{
				path: '/growth',
				name: 'growth',
				component: Growth,
				meta: { bodyClass: 'growth', title: 'Growth · Momentum Admin' },
			},
			{
				path: '/database',
				name: 'database',
				component: () => import (/* webpackChunkName: "database" */ './components/database/Database'),
				meta: { bodyClass: 'database', title: 'Database · Momentum Admin' }
			},
			{
				path: '/sales',
				component: () => import (/* webpackChunkName: "sales" */ './components/finance/Sales'),
				meta: { bodyClass: 'sales', title: 'Sales · Momentum Admin' }
			},
			{
				path: '/notifications',
				component: () => import (/* webpackChunkName: "notifications" */ './components/notifications/Notifications'),
				meta: { bodyClass: 'notifications', title: 'Notifications · Momentum Admin' },
				children: [
					{
						path: ':id',
						component: NotificationDetail
					},
					{
						path: ':id/stats',
						component: NotificationStats
					}
				]
			},
			{
				path: '/coupon-batches',
				component: () => import (/* webpackChunkName: "promo-codes" */ './components/coupon-batches/CouponBatches'),
				meta: { bodyClass: 'coupons',title: 'Coupon Batches · Momentum Admin'},
			},
			{
				path: '/login',
				component: () => import (/* webpackChunkName: "login" */ './components/login/Login'),
				meta: { bodyClass: 'login' }
			},
			{
				path: '/recover',
				component: () => import (/* webpackChunkName: "recover" */ './components/login/Recover'),
				meta: { bodyClass: 'recover', title: 'Recover · Momentum Admin' }
			},
			{
				path: '/onetime',
				component: () => import (/* webpackChunkName: "onetime" */ './components/login/Onetime'),
				meta: { bodyClass: 'onetime' }
			},
			{
				path: '/link-expired',
				component: () => import (/* webpackChunkName: "link-expired" */ './components/login/LinkExpired'),
				meta: { bodyClass: 'link-expired', title: 'Link Expired · Momentum Admin' }
			},
			{
				path: '/feature',
				component: () => import (/* webpackChunkName: "feature" */ '@/components/features/Features'),
				meta: { bodyClass: 'feature', title: 'Feature · Momentum Admin' },
				children: [
					{
						path: ':id',
						component: FeatureDetail
					}
				]
			},
			{
				path: '/experiments',
				component: () => import (/* webpackChunkName: "experiments" */ './components/experiments-new/Experiments'),
				meta: { bodyClass: 'experiments', title: 'Experiments · Momentum Admin' },
			},
			{
				path: '/experiments/:id',
				component: () => import (/* webpackChunkName: "experiment" */ './components/experiments-new/Experiment'),
				meta: { bodyClass: 'experiments', title: 'Experiment · Momentum Admin' }
			},
		]
		return routes
	}
}
